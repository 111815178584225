import React from "react";
import { Text, Image, Icon, Container, ButtonAlt } from "@atoms";
import AccordionPattern from "@svg/AccordionPattern";

const ArticleCard = ({
  title,
  authors,
  date,
  description,
  categories,
  topics,
  url,
  image,
  large,
}) => {
  const isPublication = categories?.[0]?.slug === "publications";
  return (
    <Container variant="xs" className="relative mt-4">
      <AccordionPattern className="absolute top-6 -right-20 -z-20 w-80 -rotate-90 -scale-x-100 text-teal sm:-right-52 sm:w-[30rem]" />
      <ButtonAlt
        to={categories?.length ? categories[0].url : "/articles"}
        reverse
        color="pink"
        className="decoration-pink"
      >
        <span className="flex flex-row items-center gap-2">
          <Icon name="chevron" className="h-2 rotate-180" fitHeight />
          <Text variant="body">
            {categories?.length ? categories[0].title : "All Articles"}
          </Text>
        </span>
      </ButtonAlt>
      {!isPublication && (
        <div className="relative my-6 overflow-hidden rounded-4xl shadow-lg">
          <Image
            eager
            image={image}
            ixParams={{ fit: "crop" }}
            aspectRatio={[16, 9]}
          />
        </div>
      )}
      <div>
        <Text variant="h3" className="my-6 text-purple">
          {title}
        </Text>
        {topics?.[0]?.title && (
          <div className="mt-6 mb-4 flex flex-row">
            <Text variant="sm" className="mr-1 !font-bold leading-normal">
              <span className="text-pink">Category:</span>
            </Text>
            <Text variant="sm" className="!font-bold leading-normal">
              {topics[0].title}
            </Text>
          </div>
        )}
        <div className="mt-1 flex flex-wrap gap-2 text-xs md:gap-3">
          <span>{authors}</span>
          <span className="text-pink">|</span>
          <span>{date}</span>
        </div>
        <Text variant="xs" richText className="font-medium leading-normal">
          {description}
        </Text>
      </div>
    </Container>
  );
};

export default ArticleCard;
